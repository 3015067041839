import Routes from './routes';
import { useEffect } from 'react';

function App() {
  
  useEffect(() => {
    //console.log('Referrer: ' + document.referrer);
  }, []);

  return (
    <Routes /> 
    // <>
    //   {(['https://www.google.com/', 'https://www.gstatic.com/', 'https://www.miro.com/'].includes(document.referrer)) 
    //     ? <Routes /> 
    //     : <div style={{textAlign: "center"}}>As informações não puderam ser carregadas</div>
    //   }
    // </>
  );
}

export default App;