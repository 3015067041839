import api from '../../services/api';
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";

import './style.css';

export default function Squad(){

  let params = useParams();
  
  const [squad, setSquad] = useState();
  
  useEffect(() => {
    buildSquadData();
	}, []); 

  async function buildSquadData(){
    setSquad(await getSquad(params.name));
  }

  async function getSquad(squadName){
    let response = await api.get(`/squad/${params.name}`);    
    return response.data;
  }

  return(
    <div className="container-squad-users">
      { !squad && <div>Loading... A primeira vez pode demorar um pouquinho 😉</div> }
      { squad &&            
        squad.map(user => 
            <div className="squad-user">
              <img src={user.image} />
              <span className="user-name">{user.name}</span>
              <span className="user-company">{user.company}</span>
              <span className="user-squad">{user.squad}</span>
              <span className="user-role">{user.role}</span>
              <span className="user-email">{user.email}</span>
            </div>
        )
      }      
    </div>
  );
}