import React from 'react'
import { BrowserRouter, Switch , Route } from "react-router-dom";

import NetworkMap from './pages/NetworkMap';
import Squad from './pages/Squad';

export default function Routes() {
  return (
      <BrowserRouter>
        <Switch>
          <Route path="/" exact component={NetworkMap} />
          <Route path="/network/map" component={NetworkMap} />
          <Route path="/squad/:name" component={Squad} />
        </Switch>
      </BrowserRouter>
    )
}