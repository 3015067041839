import Graph from "react-graph-vis";
import { useEffect, useState } from "react";
import api from '../../services/api';

import './style.css';
import 'vis-network/styles/vis-network.css';

function NetworkMap() {

  const [graphData, setGraphData] = useState();
  const [search, setSearch] = useState('');
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [networkInstance, setNetworkInstance] = useState();

  useEffect(() => {
    buildGraphData();
	}, []);  

  useEffect(() => {
    console.log(search);
    if(!networkInstance){
      return false;
    }

    if(search === ''){
      networkInstance.selectNodes([]);
    }

    let nodes = graphData.nodes.filter(node => node.name.toLowerCase().includes(search.toLowerCase()));
    setSelectedNodes(nodes.map(n => {
      return n.id
    }));
    networkInstance.selectNodes(selectedNodes, true);
    networkInstance.fit({
      nodes: selectedNodes
    });
	}, [search]); 

  async function getNetworkMap(){
    let response = await api.get(`/network/map`);
    return response.data;
  } 

  async function buildGraphData(){       
    setGraphData(await getNetworkMap()); 
  }

  const options = {
    layout: {
      hierarchical: false
    },
    nodes: {
      size: 30,
      borderWidth: 4,
      borderWidthSelected: 8,
      shadow: true,
      font:{
        color: "#161616",
        size: 16
      }
    },
    edges: {
      physics: true,
      color : {
        inherit: true
      },
      width: 2,
      arrows:{
        from:{
          enabled: false
        },
        to:{
          enabled: false
        }
      },
      smooth: {
        enabled: true,
        type: "dynamic",
        roundness: 3
      }
    }
  };

  const events = {
    select: function(event) {
      var { nodes, edges } = event;
      console.log(event);
    }
  };

  return (
    <div className="main-container">     
      <div className="network-box">
        {!graphData && <div style={{textAlign: "center"}}>Loading... A primeira vez pode demorar um pouquinho 😉</div>}        
        { graphData && 
          <>
            <div className="search-container">
              <input 
                id="inputSearch"
                type="text" 
                autoComplete="off"
                name="search" 
                placeholder="Search"
                onChange={event => setSearch(event.target.value)} 
              />
            </div>
            <Graph
              graph={graphData}
              options={options}
              events={events}
              getNetwork={network => {setNetworkInstance(network)}}
            />
          </>
        }
      </div>
    </div>
  );
}

export default NetworkMap;